import { useEffect, useState, Fragment, useRef } from "react";
import Navigation from "../navigation/navigation.component";
import Footer from "../../components/footer/footer.component";
import useIntersectionObserver from "../../utils/helper-functions/intersection-observer";
import "./services.styles.scss";
import "../../animation.scss";

const Services = () => {
  useEffect(() => {
    document.title = "Blissful Minds | Services";
  }, []);

  useIntersectionObserver(".hidden");

  return (
    <Fragment>
      <Navigation />
      <div className="servicePage">
        <div className="services-main-container">
          <div className="services-sub-container">
            <div className="services-image" />
            <div className="services-body-container">
              <div className="services-body-text-container hidden">
                <span className="services-title">OUR SERVICES</span>
                At Blissful Minds, we strive to improve our patients' mental
                health and well-being by providing them with the necessary
                skills to overcome their obstacles. With years of training, our
                professionals are well-equipped to provide the following
                services:
              </div>
              <div className="services-list-container">
                <div className="services-item-container">
                  <span className="services-list-item hidden">
                    Psychotherapy{" "}
                    <span className="services-checkmark">&#10004;</span>
                  </span>
                  <p className="services-item-description hidden with-delay">
                    Psychotherapy, also known as "talk therapy," is essential
                    for mental health, aiding individuals in overcoming various
                    emotional challenges. It helps identify triggers, build
                    strengths, and develop coping strategies for issues like
                    stress, trauma aftermath, medical conditions, grief,
                    depression, and anxiety. It can be used alone or alongside
                    medication and other treatments.
                  </p>
                </div>
                <div className="services-item-container">
                  <span className="services-list-item hidden">
                    Psychiatry/Medication Management{" "}
                    <span className="services-checkmark">&#10004;</span>
                  </span>
                  <p className="services-item-description hidden with-delay">
                    Psychiatric medication management is essential in mental
                    healthcare, focusing on prescribing and monitoring
                    medications for various conditions to optimize benefits and
                    safety. Commonly treated conditions include depression,
                    anxiety disorders, bipolar disorder, schizophrenia, and
                    ADHD. This approach provides convenient relief, reducing
                    symptom severity and facilitating engagement in therapy and
                    daily activities.
                  </p>
                </div>
                <div className="services-item-container">
                  <span className="services-list-item hidden">
                    Testing & Evaluation{" "}
                    <span className="services-checkmark">&#10004;</span>
                  </span>
                  <p className="services-item-description hidden with-delay">
                    Testing and evaluation for mental health conditions are
                    crucial in diagnosis and treatment, providing tailored
                    benefits for specific disorders. Blissful Minds provides
                    screenings, assessments, and evaluations for conditions
                    including Eating Disorder, Bipolar Disorder, Depression,
                    Autism, and ADHD, facilitating early diagnosis and
                    intervention. Accurate assessments enable mental health
                    professionals to develop tailored strategies to manage
                    symptoms and improve daily functioning effectively.
                  </p>
                </div>
                <a
                  className="view-full-list-a-tag hidden"
                  href="/services/#all-services"
                >
                  View Full List
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="all-services" />
        <div className="additional-services-container">
          <div className="additional-services-title-container">
            <h2 className="additional-services-title hidden">
              Pyschotherapy Services
            </h2>
            <p className="additional-services-title-body hidden with-delay">
              Helps individuals tackle emotional challenges like stress, trauma,
              grief, depression, and anxiety.
            </p>
          </div>
          <div className="additional-services-list">
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">EMDR Therapy</p>
                <p className="service-item-text">
                  EMDR therapy, or Eye Movement Desensitization and
                  Reprocessing, is a form of psychotherapy that helps
                  individuals process traumatic memories and reduce distressing
                  symptoms by engaging in guided eye movements or other forms of
                  bilateral stimulation. It aims to facilitate the brain's
                  natural healing processes and promote emotional resilience.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">Grief Counseling</p>
                <p className="service-item-text">
                  Grief counseling provides support and guidance to individuals
                  experiencing loss, helping them navigate the emotional
                  challenges associated with grief. Through therapy sessions,
                  individuals can explore their feelings, memories, and coping
                  mechanisms in a safe and compassionate environment to
                  facilitate the healing process.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">Cognitive Behavior Therapy</p>
                <p className="service-item-text">
                  Cognitive-behavioral therapy (CBT) is a widely used
                  psychotherapy approach that assists individuals identify and
                  change negative thought patterns and behaviors contributing to
                  their distress. By teaching practical coping skills and
                  promoting cognitive restructuring, CBT aims to alleviate
                  symptoms and improve overall well-being.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
          </div>

          <div className="additional-services-title-container">
            <h2 className="additional-services-title hidden">
              Psychiatry/Medication Management
            </h2>
            <p className="additional-services-title-body hidden with-delay">
              Involves prescribing and monitoring medications to alleviate
              mental health conditions, facilitating therapy engagement and
              daily functioning.
            </p>
          </div>
          <div className="additional-services-list">
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">ADHD Medication Management</p>
                <p className="service-item-text">
                  ADHD medication management comprise the use of stimulant and
                  non-stimulant medications to alleviate symptoms such as
                  inattention, hyperactivity, and impulsivity. These medications
                  are typically prescribed as part of a comprehensive treatment
                  plan that may also include therapy and behavioral
                  interventions.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">ADHD Treatment</p>
                <p className="service-item-text">
                  ADHD treatment typically involves a combination of medication,
                  such as stimulants or non-stimulants, and behavioral therapy
                  to manage symptoms of inattention, hyperactivity, and
                  impulsivity. Tailored treatment plans aim to improve focus,
                  organization, and self-control, enhancing functioning and
                  quality of life for individuals with ADHD.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">Anxiety Disorder Treatments</p>
                <p className="service-item-text">
                  Treatment for anxiety disorders include therapy, medication,
                  and self-care strategies like exercise and stress management.
                  Cognitive-behavioral therapy (CBT) and antidepressant
                  medications are commonly used approaches to alleviate symptoms
                  and improve quality of life.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Anxiety Medication Management
                </p>
                <p className="service-item-text">
                  Anxiety medication management often involves the use of
                  antidepressants, benzodiazepines, or other medications to
                  alleviate symptoms such as excessive worry, fear, and panic.
                  These medications are prescribed alongside therapy and
                  self-care strategies, aiming to reduce anxiety levels and
                  improve overall quality of life.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">Bipolar Disorder Treatment</p>
                <p className="service-item-text">
                  Treatment typically emcompass mood-stabilizing medications to
                  manage mood swings and prevent manic or depressive episodes.
                  Additionally, therapy, lifestyle changes, and support networks
                  play vital roles in stabilizing mood, promoting adherence to
                  treatment, and enhancing overall well-being for individuals
                  with bipolar disorder.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Depression Medication Treatment
                </p>
                <p className="service-item-text">
                  Depression medication treatment often utilizes the use of
                  antidepressants to alleviate symptoms such as persistent
                  sadness, loss of interest, and low energy. These medications
                  are prescribed alongside therapy and self-care strategies.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Depression Treatment Options
                </p>
                <p className="service-item-text">
                  {" "}
                  Typically include a combination of therapy, medication, and
                  lifestyle changes. Therapy, such as cognitive-behavioral
                  therapy (CBT) or interpersonal therapy.Medications, such as
                  antidepressants, may be prescribed to alleviate symptoms.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Postpartum Depression Treatments
                </p>
                <p className="service-item-text">
                  Treatment involves a combination of therapy, medication, and
                  support from healthcare professionals. Therapy, such as
                  cognitive-behavioral therapy (CBT) or interpersonal therapy,
                  helps mothers address their feelings and develop coping
                  strategies, while medications may be prescribed to alleviate
                  symptoms.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Post Traumatic Stress Disorder Treatments
                </p>
                <p className="service-item-text">
                  Regulary incorporate therapy, such as cognitive processing
                  therapy (CPT) or eye movement desensitization and reprocessing
                  (EMDR), to help individuals process traumatic experiences and
                  reduce symptoms. Medications, such as antidepressants or
                  anti-anxiety medications, may also be prescribed.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Premenstrual Dysphoric Disorder Treatments
                </p>
                <p className="service-item-text">
                  PMDD treatments encompass a range of approaches, including
                  medication such as antidepressants or hormonal treatments, as
                  well as lifestyle modifications like exercise, stress
                  management, and dietary changes, all aimed at alleviating mood
                  symptoms and physical discomfort associated with the disorder.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
          </div>

          <div className="additional-services-title-container">
            <h2 className="additional-services-title hidden">
              Testing & Evaluation Services
            </h2>
            <p className="additional-services-title-body hidden with-delay">
              Offers essential testing & evaluation services for mental health
              conditions, enabling early intervention and tailored treatment
              plans.
            </p>
          </div>
          <div className="additional-services-list">
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">ADHD Testing and Evaluation</p>
                <p className="service-item-text">
                  ADHD testing and evaluation include comprehensive assessments
                  conducted by healthcare professionals to diagnose ADHD. These
                  assessments typically include interviews, observation, rating
                  scales, and psychological testing to evaluate symptoms and
                  rule out other possible causes.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Depression Screening and Evaluation
                </p>
                <p className="service-item-text">
                  Entail assessing an individual's mood, thoughts, and behaviors
                  to determine the presence and severity of depressive symptoms.
                  This process often includes questionnaires, interviews, and
                  clinical assessments to aid in diagnosis and treatment
                  planning.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
            <div className="service-item-box hidden">
              <div className="service-item-body">
                <p className="service-item-name">
                  Anxiety Screening and Evaluation
                </p>
                <p className="service-item-text">
                  Anxiety screening and evaluation involve assessing an
                  individual's thoughts, emotions, and behaviors to identify
                  symptoms of anxiety disorders. This process may include
                  questionnaires, interviews, and clinical assessments to aid in
                  diagnosis and treatment planning.
                </p>
              </div>
              <input className="service-expand-btn" type="checkbox" />
            </div>
          </div>
          <p className="services-contact">
            Interested in a service, but is not listed above?{" "}
            <a className="services-contact-link" href="/contact">
              Contact us
            </a>{" "}
            for more information.
          </p>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Services;
