import { useEffect } from "react";

const useIntersectionObserver = (selector: string) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(selector);
    if (hiddenElements) {
      hiddenElements.forEach((el: Element) => observer.observe(el));
    }

    return () => {
      // Cleanup: disconnect the observer when the component unmounts
      observer.disconnect();
    };
  }, [selector]); // Re-run effect if the selector changes
};

export default useIntersectionObserver;
