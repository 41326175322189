import { Fragment, useEffect } from "react";
import Navigation from "../navigation/navigation.component";
import ContactMessage from "../../components/contact/contactMessage.component";
import Footer from "../../components/footer/footer.component";
import useIntersectionObserver from "../../utils/helper-functions/intersection-observer";
import "./contact.styles.scss";
import "../../animation.scss";

const Contact = () => {
  useEffect(() => {
    document.title = "Blissful Minds - Contact";
  });

  useIntersectionObserver(".hidden");

  return (
    <Fragment>
      <Navigation />
      <div className="contact-container">
        <div className="banner-container">
          <div className="banner-img" />
          <h1 className="contact-title hidden">Connect with us</h1>
        </div>

        <div className="contact-info-container hidden">
          <div className="contact-info">
            <h4>Phone & Email</h4>
            <h2>Our Contact Information</h2>
            <span className="contact-info-text">
              <span className="contact-info-icons">&#x1F5D3;</span> Mon - Fri
              from 8am PST - 5pm PST
            </span>
            <span className="contact-info-text">
              <span className="contact-info-icons">&#9742;</span> +1 (323)
              638-9775
            </span>
            <span className="contact-info-text">
              <span className="contact-info-icons">&#9993;</span>{" "}
              blissfulmmh@gmail.com
            </span>
            <span className="contact-info-text">
              <img
                className="contact-location-icon"
                src="https://i.imgur.com/0KlSde2.png"
                alt="contact-location-icon"
              />
              1961 Huntington Drive Suite 200 Alhambra, CA 91801
            </span>
          </div>
        </div>
        <div className="contact-messaging-container hidden with-delay">
          <div className="messaging-container">
            <h4>Message Form</h4>
            <h2>Have a question? Don't hesitate to reach out to us!</h2>
            <ContactMessage />
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Contact;
