import { useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
import Insurance from "../../components/insurance/insurance.component";
import Navigation from "../navigation/navigation.component";
import useIntersectionObserver from "../../utils/helper-functions/intersection-observer";
import "./home.styles.scss";
import "../../animation.scss";

const Home = () => {
  const navigate = useNavigate();

  const navigateRegister = () => navigate("/register");

  useEffect(() => {
    document.title = "Blissful Minds | Home";
  });

  useIntersectionObserver(".hidden");

  return (
    <Fragment>
      <Navigation />
      <div className="home-container">
        <div className="home-image-container">
          <div className="homePage-body hidden with-delay">
            <h1 className="homePage-body-text">
              Welcome to a journey of self-discovery and mental well-being. At
              Blissful Minds, we believe in fostering a compassionate space for
              healing and growth. Explore the path to a healthier mind with us.
            </h1>
            <button
              className="book-appointment-button"
              onClick={navigateRegister}
            >
              BOOK AN APPONTMENT TODAY
            </button>
          </div>
        </div>
        <Insurance />
        <Footer />
      </div>
    </Fragment>
  );
};

export default Home;
