import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./footer.styles.scss";

const Footer = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className={`footer-container ${isHomePage ? "home-page" : ""}`}>
      <div className="footer-img-container">
        <a href="/">
          <img
            className="footer-img"
            src="https://i.imgur.com/GkDN4vk.png"
            alt="footer-img"
          />
        </a>
      </div>
      <div className="footer-about-container">
        <h2 className="footer-about-header">RESOURCES</h2>

        <a className="footer-resources-link" href="/about">
          About Us
        </a>
        <a className="footer-resources-link" href="/services">
          Services
        </a>
        <a className="footer-resources-link" href="/register">
          Make Appointment
        </a>
      </div>
      <div className="footer-contact-container">
        <a href="/contact">
          <h2 className="footer-contact-header">CONTACT</h2>
        </a>
        <span className="footer-contact-info">
          &#x1F5D3; Mon - Fri from 8am PST - 5pm PST
        </span>
        <span className="footer-contact-info">&#9742; +1 (323) 638-9775</span>
        <span className="footer-contact-info">
          &#9993; blissfulmmh@gmail.com
        </span>
      </div>
      <h4 className="copyright">
        Copyright &#169; 2023 Blissful Minds Mental Health, Inc.
      </h4>
    </div>
  );
};

export default Footer;
