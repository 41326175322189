import { useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../navigation/navigation.component";
import Footer from "../../components/footer/footer.component";
import useIntersectionObserver from "../../utils/helper-functions/intersection-observer";
import "./about.styles.scss";
import "../../animation.scss";

const About = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Blissful Minds | About";
  });

  const handleSeeAll: () => void = () => {
    navigate("/services");
  };

  useIntersectionObserver(".hidden");

  return (
    <Fragment>
      <Navigation />
      <div className="aboutPage-container">
        <div className="about-container">
          <div className="about-body">
            <h1 className="about-title hidden">About Us</h1>
            <div className="about-text-container hidden with-delay">
              <span className="about-body-text">
                At our online mental health provider, we are dedicated to
                fostering a supportive and inclusive community where individuals
                can access the resources and tools they need to improve their
                mental well-being. Our mission is to empower individuals to take
                control of their mental health by providing evidence-based,
                personalized, and accessible online services that meet the
                unique needs of each individual. We believe that mental health
                is a fundamental aspect of overall well-being, and it is our
                goal to help as many people as possible achieve this. We strive
                to create a welcoming and non-judgmental environment where
                individuals can feel safe to explore their mental health and
                work towards their goals. Join us on a journey towards better
                mental health, and together we can make a difference.{" "}
                <a href="/services">
                  <span className="see-services-span">See Our Services</span>
                </a>
              </span>
              <button
                className="see-services-button hidden with-delay"
                onClick={handleSeeAll}
              >
                See Our Services
              </button>
            </div>
            <input className="about-expand-btn" type="checkbox" />
          </div>
          <div className="about-image" />
        </div>

        <div id="team" />
        <div className="meet-our-members-container">
          <h2 className="meet-our-members-title hidden">
            Meet Our Specialists
          </h2>
          <span className="meet-our-members-body hidden with-delay">
            Our team of mental health professionals are committed to providing
            you with the necessary tools to lead a happy & healthy life.
          </span>
        </div>
        <div className="members-main-container">
          <div className="members-container hidden">
            <div className="member-image-container">
              <div className="marvin-headshot" />
              <div className="member-info-container">
                <span className="member-name">Marvin Hoang</span>
                <span className="member-job-title">
                  {" "}
                  <img
                    className="job-title-icon"
                    src="https://i.imgur.com/a3P9EiZ.png"
                    alt="member-job"
                  />
                  Psychiatric Nurse Practicioner
                </span>
                <span className="member-location">
                  {" "}
                  <img
                    className="location-icon"
                    src="https://i.imgur.com/0KlSde2.png"
                    alt="member-location"
                  />{" "}
                  Los Angeles, California
                </span>
                <span className="member-languages">
                  <img
                    className="languages-icon"
                    src="https://i.imgur.com/NOm1oUi.png"
                    alt="member-languages"
                  />
                  English & Vietnamese
                </span>
                <div className="member-tags">
                  <img
                    className="tags-icon"
                    src="https://i.imgur.com/w04BPRs.png"
                    alt="member-tags"
                  />
                  <div className="tags-container">
                    <span className="tags">Hollistic</span>
                    <span className="tags">Energetic</span>
                    <span id="third-tag" className="tags">
                      Solution Oriented
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="member-body-container">
              <div className="member-body">
                <h3 className="member-body-header">About Myself ...</h3>
                <span className="member-body-text">
                  I am a Psychiatric Mental Health Nurse Practitioner with
                  expertise in holistic treatment of mental health, including
                  anxiety, depression, ADHD, bipolar, and schizophrenia. I
                  attended Walden University for my graduate program and
                  Midwestern State University for my undergraduate degree. I am
                  committed to providing personalized care to each client, using
                  evidence-based practices to achieve optimal outcomes.
                </span>

                <h3 className="member-body-header">Therapy approach ...</h3>
                <span className="member-body-text">
                  I utilize a holistic approach to mental health treatment which
                  considers the whole person, including their physical,
                  emotional, and social well-being. I believe in identifying and
                  addressing the root cause of mental health issues instead of
                  just treating the symptoms.
                </span>

                <h3 className="member-body-header">
                  What to expect from our first session ...
                </h3>
                <span className="member-body-text">
                  Clients can expect to receive personalized care that addresses
                  their unique needs and concerns. This may include medication
                  management, therapy, lifestyle modifications, and other
                  evidence-based practices that aim to achieve optimal outcomes
                  and enhance overall quality of life.
                </span>
                <h3 className="member-body-header">Focus treatment areas</h3>
                <span className="member-body-text">&#10031; Depression </span>
                <span className="member-body-text">
                  &#10031; Bipolar Disorder
                </span>
                <span className="member-body-text">
                  &#10031; Attention Deficit Disorder(ADD/ADHD)
                </span>
                <span className="member-body-text">&#10031; Anxiety</span>
                <h3 className="member-body-header">Treatment areas</h3>
                <span className="member-body-text">
                  &#9900; Anger Management/Impulse Disorder
                </span>
                <span className="member-body-text">
                  &#9900; Manic Depression Illness
                </span>
                <span className="member-body-text">
                  {" "}
                  &#9900; Obsessive Compulsive Disorder
                </span>
                <span className="member-body-text">&#9900; Relationships</span>
                <span className="member-body-text">
                  &#9900; Post Traumatic Stress Disorder
                </span>
                <span className="member-body-text">
                  &#9900; Grief/Bereavement
                </span>
                <span className="member-body-text">
                  &#9900; Sleep Disorders
                </span>
              </div>
              <input className="expand-btn" type="checkbox" />
            </div>
          </div>

          <div className="members-container hidden">
            <div className="member-image-container">
              <div className="claris-headshot" />
              <div className="member-info-container">
                <span className="member-name">Claris Bangura</span>
                <span className="member-job-title">
                  {" "}
                  <img
                    className="job-title-icon"
                    src="https://i.imgur.com/a3P9EiZ.png"
                    alt="member-job"
                  />
                  Psychiatric Nurse Practicioner
                </span>
                <span className="member-location">
                  {" "}
                  <img
                    className="location-icon"
                    src="https://i.imgur.com/0KlSde2.png"
                    alt="member-location"
                  />{" "}
                  Los Angeles, California
                </span>
                <span className="member-languages">
                  <img
                    className="languages-icon"
                    src="https://i.imgur.com/NOm1oUi.png"
                    alt="member-languages"
                  />
                  English
                </span>
                <div className="member-tags">
                  <img
                    className="tags-icon"
                    src="https://i.imgur.com/w04BPRs.png"
                    alt="member-tags"
                  />
                  <div className="tags-container">
                    <span className="tags">Holistic</span>
                    <span className="tags">Open Minded</span>
                    <span id="third-tag" className="tags">
                      Empowering
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="member-body-container">
              <div className="member-body">
                <h3 className="member-body-header">About Myself ...</h3>
                <span className="member-body-text">
                  Hello, I'm Claris Bangura, a Nurse Practitioner specializing
                  in supporting adults through depression, anxiety, and a range
                  of other mental health disorders. With a Master of Science in
                  Nursing from Walden University, I'm here to provide
                  personalized care and guidance tailored specifically for
                  adults on their journey to mental well-being. Let's work
                  together to overcome challenges and achieve lasting wellness.
                </span>

                <h3 className="member-body-header">Therapy approach ...</h3>
                <span className="member-body-text">
                  As a psychiatric nurse practitioner, I bring a personalized
                  and empathetic approach to mental health care. My style is
                  rooted in collaboration, where I work closely with each
                  individual to develop tailored treatment plans that honor
                  their unique experiences and goals. I specialize in
                  evidence-based interventions that promote healing and growth.
                </span>

                <h3 className="member-body-header">
                  What to expect from our first session ...
                </h3>
                <span className="member-body-text">
                  Clients can expect to take away a sense of empowerment,
                  understanding, and hope from sessions with me. Through our
                  collaborative work, they will gain practical tools to manage
                  their symptoms, deepen their self-awareness, and develop
                  strategies for navigating life's challenges with resilience.
                  My goal is to provide a supportive and nonjudgmental space
                  where clients feel heard, validated, and equipped to make
                  positive changes in their lives.
                </span>
                <h3 className="member-body-header">Focus treatment areas</h3>
                <span className="member-body-text">&#10031; Depression </span>
                <span className="member-body-text">
                  &#10031; Bipolar Disorder
                </span>
                <span className="member-body-text">
                  &#10031; Manic Depression Illness
                </span>
                <span className="member-body-text">&#10031; Anxiety</span>
                <span className="member-body-text">
                  &#10031; Attention Deficit Disorder(ADD/ADHD)
                </span>
                <span className="member-body-text">
                  &#10031; Post Traumatic Stress Disorder
                </span>
                <h3 className="member-body-header">Treatment areas</h3>
                <span className="member-body-text">
                  {" "}
                  &#9900; Obsessive Compulsive Disorder
                </span>
                <span className="member-body-text">&#9900; Relationships</span>
                <span className="member-body-text">
                  &#9900; Grief/Bereavement
                </span>
                <span className="member-body-text">&#9900; Trauma</span>
              </div>
              <input className="expand-btn" type="checkbox" />
            </div>
          </div>

          <div className="members-container hidden">
            <div className="member-image-container">
              <div className="ruby-headshot" />
              <div className="member-info-container">
                <span className="member-name">Ruby Phun</span>
                <span className="member-job-title">
                  {" "}
                  <img
                    className="job-title-icon"
                    src="https://i.imgur.com/E2MnSA4.png"
                    alt="member-job"
                  />
                  Certified Marriage & Family Therapist
                </span>
                <span className="member-location">
                  {" "}
                  <img
                    className="location-icon"
                    src="https://i.imgur.com/0KlSde2.png"
                    alt="member-location"
                  />{" "}
                  Los Angeles, California
                </span>
                <span className="member-languages">
                  <img
                    className="languages-icon"
                    src="https://i.imgur.com/NOm1oUi.png"
                    alt="member-languages"
                  />
                  English, Cantonese & Vietnamese
                </span>
                <div className="member-tags">
                  <img
                    className="tags-icon"
                    src="https://i.imgur.com/w04BPRs.png"
                    alt="member-tags"
                  />
                  <div className="tags-container">
                    <span className="tags">Warm</span>
                    <span className="tags">Open Minded</span>
                    <span id="third-tag" className="tags">
                      Empowering
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="member-body-container">
              <div className="member-body">
                <h3 className="member-body-header">About Myself ...</h3>
                <span className="member-body-text">
                  Hi there! My name is Ruby and I am passionate about helping
                  individuals who struggle to find their place in the family,
                  community, relationships, boundaries, and sense of self. My
                  goal is to empower you to find a healthy balance between
                  yourself and your loved ones.
                </span>

                <h3 className="member-body-header">Therapy approach ...</h3>
                <span className="member-body-text">
                  I provide a non-judgmental space for you to self-reflect and
                  build self-awareness. I will tailor therapy to your needs by
                  utilizing cognitive-behavioral, strengths-based, and
                  client-centered approaches.
                </span>

                <h3 className="member-body-header">
                  What to expect from our first session ...
                </h3>
                <span className="member-body-text">
                  I want to empower you to understand your thoughts and
                  feelings. Working through your negative behavioral and thought
                  patterns, we will identify how these currently affect your
                  life. I will help you find solutions within yourself that you
                  feel comfortable practicing and sustaining, using your natural
                  strengths. Together we will discover what more positive and
                  healthy coping skills feel more natural to you.
                </span>
                <h3 className="member-body-header">Focus treatment areas</h3>
                <span className="member-body-text">&#10031; Relationships</span>
                <span className="member-body-text">&#10031; Anxiety</span>
                <span className="member-body-text">&#10031; Depression</span>
                <span className="member-body-text">
                  &#10031; Stress Management
                </span>
                <span className="member-body-text">&#10031; Trauma</span>
                <h3 className="member-body-header">Treatment areas</h3>
                <span className="member-body-text">
                  &#9900; Post Traumatic Stress Disorder
                </span>
                <span className="member-body-text">
                  &#9900; Anger Management
                </span>
                <span className="member-body-text">
                  &#9900; Impulse Disorder
                </span>
                <span className="member-body-text">
                  &#9900; Gay/Lesbian/Bisexual Issues
                </span>
                <span className="member-body-text">&#9900; Family Issues</span>
                <span className="member-body-text">
                  &#9900; Grief/Bereavement
                </span>
              </div>
              <input className="expand-btn" type="checkbox" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default About;
