import { Routes, Route } from "react-router-dom";
import { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import {
  onAuthStateChangedListener,
  createUserDocumentFromAuth,
} from "./utils/firebase/firebase.utils";
import { setCurrentUser } from "./store/user/user.action";
import PrivateRoute from "./routes/PrivateRoutes";
import Login from "./routes/login/login.component";
import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import About from "./routes/about/about.component";
import Contact from "./routes/contact/contact.component";
import GetStarted from "./routes/getStarted/getStarted.component";
import Services from "./routes/services/services.component";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubsribe = onAuthStateChangedListener((user: any) => {
      if (user) {
        createUserDocumentFromAuth(user);
      }
      dispatch(setCurrentUser(user));
    });

    return unsubsribe;
  }, [dispatch]);

  return (
    <Fragment>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/" element={<Navigation />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/register/" element={<GetStarted />} />
        <Route path="/about/" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact/" element={<Contact />} />
      </Routes>
    </Fragment>
  );
}

export default App;
