import { useLocation } from "react-router-dom";
import useIntersectionObserver from "../../utils/helper-functions/intersection-observer";
import "./insurance.styles.scss";
import "../../animation.scss";

const Insurance = () => {
  const location = useLocation();
  const isServicePage = location.pathname === "/services";

  useIntersectionObserver(".hidden");

  return (
    <div className="accepted-insurances">
      <div className="accepted-insurance-text-container hidden">
        <h1 className="accepted-insurance-title">Accepted Insurances</h1>
        <p className="accepted-insurance-text">
          Our network includes Aetna, Cigna, United Healthcare, and more. We
          also accept cash payments.{" "}
          <a className="accepted-insurance-contact-link" href="/contact">
            Contact us
          </a>{" "}
          for more information.
        </p>
      </div>
      <img
        className="insurance1 insurance-logo hidden"
        src="https://i.imgur.com/jujVRSp.png"
        alt="logo"
      />
      <img
        className="insurance1 insurance-logo hidden"
        src="https://i.imgur.com/NC0uSE5.png"
        alt="logo"
      />
      <img
        className="insurance1 insurance-logo hidden"
        src="https://i.imgur.com/pvQ4joO.png"
        alt="logo"
      />
      <img
        className="insurance2 insurance-logo hidden"
        src="https://i.imgur.com/d2PpnZ2.png"
        alt="logo"
      />
      <img
        className="insurance3 insurance-logo hidden"
        src="https://i.imgur.com/tdnI0xi.png"
        alt="logo"
      />
      <img
        className="insurance4 insurance-logo hidden"
        src="https://i.imgur.com/cPIwUua.png"
        alt="logo"
      />
    </div>
  );
};

export default Insurance;
