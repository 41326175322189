import { Outlet, Link, useFetcher } from "react-router-dom";
import { useEffect } from "react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./navigation.styles.scss";

const Navigation = () => {
  const [showLinks, setShowLinks] = useState(false);

  useEffect(() => {
    const navBar = document.querySelector(".toggle-button");

    if (navBar) {
      navBar.addEventListener("click", (event) => {
        event.preventDefault();
      });
    }
  });

  const toggleShow = () => {
    setShowLinks(!showLinks);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  const navigate = useNavigate();

  const viewTeam = () => {
    navigate("/about/#team");
  };

  const handleClick = () => {
    setShowLinks(false);
  };

  return (
    <div className="navigation">
      <Link className="logo-container" to="/" onClick={scrollToTop}>
        <img
          className="logo-img"
          src="https://i.imgur.com/AaZRuAb.png"
          alt="logo"
        />
      </Link>
      <a href="#" className="toggle-button" onClick={toggleShow}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      <div className="nav-links-container" id={showLinks ? "hidden" : ""}>
        <Link
          className="nav-link"
          to="/"
          onClick={() => {
            setShowLinks(false);
            scrollToTop();
          }}
        >
          HOME
        </Link>
        <div className="sub-nav-links-container">
          <Link
            className="nav-link"
            to="/about/"
            onClick={() => {
              setShowLinks(false);
              scrollToTop();
            }}
          >
            ABOUT
          </Link>
          <a
            className="sub-nav-link"
            href="/about/#team"
            onClick={() => {
              setShowLinks(false);
              viewTeam();
            }}
          >
            Team
          </a>
        </div>
        <Link
          className="nav-link"
          to="/services/"
          onClick={() => {
            setShowLinks(false);
            scrollToTop();
          }}
        >
          SERVICES
        </Link>
        <Link
          className="nav-link"
          to="/contact/"
          onClick={() => {
            setShowLinks(false);
            scrollToTop();
          }}
        >
          CONTACT
        </Link>
        <Link
          className="book-appointment-link"
          to="/register/"
          onClick={() => {
            setShowLinks(false);
            scrollToTop();
          }}
        >
          BOOK APPOINTMENT
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
