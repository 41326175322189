type SubjectProps = {
  name: string;
  // error: string;
  type: string;
  className: string;
  // required: "required";
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MessageSubject = (props: SubjectProps) => {
  return (
    <div className="form-group">
      <input
        type={props.type}
        name={props.name}
        className={props.className}
        // required={this.props.required}
        onChange={props.onChange}
        value={props.value}
        placeholder="Subject (Optional)"
      />
    </div>
  );
};

export default MessageSubject;
