import { useEffect, Fragment } from "react";
import Navigation from "../navigation/navigation.component";
import useIntersectionObserver from "../../utils/helper-functions/intersection-observer";
import "./getStarted.styles.scss";
import "../../animation.scss";

const GetStarted = () => {
  useEffect(() => {
    document.title = "Blissful Minds | Register";
  });

  useIntersectionObserver(".hidden");

  return (
    <Fragment>
      <Navigation />
      <div className="getStarted-container">
        <div className="img-container" />
        <div className="getStarted-text-container">
          <div className="getStarted-text-sub-container">
            <h1 className="schedule-app-title hidden">
              Schedule An Appointment
            </h1>
            <div className="getStarted-services-container">
              <h2 className="getStarted-services-list hidden">
                For Psychotherapy or Psychiatry/Medication Management
              </h2>
              <h4 className="schedule-app-text hidden with-delay">
                Register an account through the Headway portal to book your
                first appointment.
              </h4>
              <a
                className="register-link hidden with-delay"
                target="_blank"
                href="https://headway.co/providers/marvin-hoang"
              >
                Register with Marvin
              </a>
              <a
                className="register-link hidden with-delay"
                target="_blank"
                href="https://headway.co/providers/claris-bangura"
              >
                Register with Claris
              </a>
            </div>
            <div className="getStarted-services-container">
              <h2 className="getStarted-services-list hidden">
                For EMDR Therapy, Grief Counseling, or Cognitive Behavior
                Therapy
              </h2>
              <h4 className="schedule-app-text hidden with-delay">
                Register an account through the Headway portal to book your
                first appointment.
              </h4>
              <a
                className="register-link hidden with-delay"
                target="_blank"
                href="https://headway.co/providers/ruby-phun"
              >
                Register with Ruby
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GetStarted;
